
.availability-container{
    
    #specific_date_selector{ 
        height: 40.5px;
    }

    .availability-control-container{
        background-color: white;
        border: 1px solid #F2F2F2;
        border-radius: 0.6rem;
        overflow: hidden;
        padding: 10px;
    }

    .availability-control-grid {
        border: none;
        border-radius: 0;
    }
    .segmented-control{
        width: 60%;
        margin: auto;
    }

    .availability-row:last-child {
        border-bottom: 1px dashed #212529;
    }

    .availability-row.start {
        border-top: 1px dashed #212529;
    }

    .availability-control-times p:empty {
        display: none;
    }

    .availability-control-times p {
        margin: 0;
        margin-top: -0.75rem;
        margin-right: 5px;
        text-align: center;
        height: 1.5rem;
        flex: 1;
        align-self: auto;
    }
}