.reservation-list {
    .reservation-list-item {
        &:hover {
            color: initial;
        }

        &:not(.clickable) {
            pointer-events: none;
            cursor: initial;
        }

    }
}