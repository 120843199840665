#fileUpload {
    display: none;
}

#uploadContainer {
    display: flex;
    width: 100%;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: 20px;
    border-radius: 0.6rem;
    border: 1px solid #E7E7E7;
    padding: 0;
    cursor: pointer;
    overflow: hidden;

    .disabled {
        cursor: no-drop;
    }
}

#uploadedImage {
    display: block;
    width: 100%;
    height: 300px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0 auto;
}

#uploadIcon {
    font-size: 2rem;
    width: 54px;
    height: 54px;
    margin: 0 auto;
}

#cropper-modal {
    .modal-body{
        max-height: 90vh;
    }

    .image-cropper, #cropping-image{
        max-height: 75vh;
        margin: auto;
    }
}