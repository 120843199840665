.segmented-control {
	display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F2F2F2;
	justify-content: space-evenly;
	width: 100%;

	.segment {
		line-height: 20px;
		text-align: center;
		letter-spacing: 0px;
		text-transform: capitalize;
		cursor: pointer;
		font-weight: bold;
		flex: 1;
		order: 0;
		// flex-grow: 0;
		margin: 0px 10px !important;
		font-size: 14.4px;
		padding: 1em;
		border-radius: 0.5em 0.5em 0 0 !important;

		&.selected {
			background-color: #F2F2F2;
		}

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}