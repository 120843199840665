.basic-layout .content-area {
  height: calc(100% - 7rem) !important;
  min-height: calc(100vh - 7rem) !important;
}

.basic-layout .nav-tabs {
  padding-left: 2rem;
}

@media (min-width: 420px) {
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

// HUI 1 overrides 
.detail .detail-contents {
  background: linear-gradient(180deg, #e1e6ec 0%, #fff 20%);
}