@mixin row {
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 100;
}

body {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.list-group {
  list-style: none;
}

.list-group.loading .skeleton {
  background: #c0c3c7 !important;
}

.action-buttons-container * {
  color: black;
}

.first-in-row * {
  font-weight: bold;
}
.multi-fill-body {
  height: 16px !important;
}
.advanced-table * {
  font-size: 16px;
}

.reservations-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.reservations-container {
}

.times-table-row {
  @include row;
}

.times-table-row p {
  margin: 0;
  font-size: 0.9rem;
  text-align: right;
  padding-right: 1rem;
}

.times-table-row > * {
  flex: 2;
}

.bold-text {
  flex: 2 1;
  font-weight: 500;
}

.times-table-row .fill-bar {
  flex: 9;
}

.times-table-row > span {
  text-align: right;
}

.times-table-header {
  @include row;
}

.times-table-header > * {
  flex: 2;
  font-size: 0.9rem;
  text-align: right;
}

.times-table-header > :first-child {
  flex: 2 1;
  font-size: 0.9rem;
  text-align: right;
}

.times-table-header .header-graph {
  flex: 9;
}

.names-table-row {
  @include row;
}

.names-table-row > * {
  flex: 3;
}
.names-table-row {
  i {
    max-width: 2rem;
  }
}
.names-table-row > p {
  margin: 0;
}

.names-table-row > img {
  max-width: 30px;
  margin-right: 15px;
}

.names-table-row > span {
  text-align: right;
  flex: 1;
}

.names-table-container {
  margin-top: 25px;
}

.btn-icon-basic {
  color: #175c5f !important;
}

.pseudoHeader {
  //position:absolute;
  //z-index: 50;
}
.date-picker-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.edit-space {
  flex: 3;
  justify-self: flex-end;
  text-align: right;
}

.add-space-form {
  label {
    margin-bottom: 0;
  }
}

.negative-padding {
  margin-left: -3rem;
}

.no-margin {
  margin: 0;
}

.list-group-item {
  display: flex;
  border: none;
}

.list-group-item.hasIcon .additional{
  margin-left: 2rem !important;
}

.list-group-item.active {
    background-color: #007BC2 !important;
    border-color: #007BC2 !important;
    border-radius: 0.7rem ;
}

.list-group-item i {
  padding-right: 0 !important;
}

.profile-pic {
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
  }
}

.profile-pic-large {
  width: 5rem;
  height: 5rem;
  display: inline-block;
  border-radius: 3rem;
  background: #eee;
  overflow: hidden;

  img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
  }
}

.profile-pic-large i {
  margin: 2rem;
}

.btn-danger {
  background-color: #e3124b;
  border-color: #e3124b;
}

.btn-outlined-danger {
  color: #E3124B;
  background-color: transparent;
  border: 1px solid rgba(227, 18, 75, 0.25);
}

.btn-outlined-success {
  background-color: transparent;
  border: 1px solid rgba(2, 150, 0, 0.25);
  color: #029600;
}

.btn-information {
  button {
    color: #000;
    // background-color: #e1e6ec;
    background-color: rgba(0, 0, 0, 0.1);
    // border-color: #e1e6ec;
  }
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
  color: rgba(0,0,0,0.4) !important;
  border-color: rgba(0,0,0,0.4) !important;
}

.no-select {
  &:hover {
    cursor: default !important;
    color: #000 !important;
  }

  // background-color: transparent !important;
  background-color: transparent;
}

.fulltime-cadence {
  width: 10px;
  height: 10px;
  background-color: #20447d;
  border: 1px solid rgba(0, 0, 0, 0.253138);
  box-sizing: border-box;
  border-radius: 1rem;
}

.once-cadence {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, 0.253138);
  box-sizing: border-box;
  border-radius: 1rem;
}

.sub-title {
  border-radius: 0.4rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  // border: 1px solid rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
  margin-top: 0.3rem;

  .left {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
  }

  .right {
    border-bottom-right-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    // background-color: black;
    background-color: white;
    // color: #fff;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    display: inline-block;
  }
}

.cadance-card {
  padding: 1rem;
  margin: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.253138);
  border-radius: 0.6rem;
  cursor: pointer;

  &:hover {
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  }
}

.cadance-card.selected {
  // border: 1px solid #20447d !important;
  border-radius: 0.6rem;
  background-color: #007BC2;
  color: white;

  .cadance-icon {
    color: #20447d;
    // color: #20447d !important;
  }
}

.master-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 0 1.5rem;
}

.image-icon {
  i {
    font-size: 3rem;
  }
}

.user-start {
  color: rgba(0, 0, 0, 0.3);
}

.success-badge {
  border: none !important;
  color: rgb(0, 165, 0);
  background-color: rgba(0, 165, 0, 0.125);
}

.warning-badge {
  border: none !important;
  color: rgb(255, 165, 0);
  background-color: rgba(255, 165, 0, 0.125);
}

.danger-badge {
  border: none !important;
  color: rgb(165, 0, 0);
  background-color: rgba(165, 0, 0, 0.125);
}

.green-badge {
  border: none !important;
  color: rgb(2, 150, 0);
  background-color: rgba(2, 150, 0, 0.1);
}

.list-group-item.approved {
  // border-color: rgb(0, 165, 0) !important;
  background-color: rgba(2, 150, 0, 0.1);
}

.list-group-item.denied {
  // border-color: rgb(165, 0, 0) !important;
  background-color: rgba(227, 18, 75, 0.1);
}

.list-group-item.pending {
  border-color: rgb(255, 165, 0) !important;
}

.form-group {
  margin-bottom: 0px;
}
